html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text inside the heading */
  height: 100%; /* Full height of the container */
}

h1 {
  font-size: 1.3em;
  margin-bottom: 10px; /* Add space between the heading and the buttons */
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  color: #fff; /* Ensure heading text is visible */
  margin-top: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 60px;
  background-image: url("../gtk-bki-cropped.jpg");
}

.contact-button {
  background-color: #8a2be2; /* Bright purple */
  color: white;
  padding: 7.8px 3.8px;
  border-radius: 5px;
  font-family: "Calibri";
}

/* .header {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  display: flex; 
  align-items: stretch; 
  justify-content: space-between;
  background-image: linear-gradient(
    to bottom,
    rgba(40, 44, 52, 0.9),
    rgb(55, 62, 75)
  );
  color: white; 
  padding: 0 16px; 
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  max-height: 78px; 
  border-radius: 6px; 
  overflow: hidden; 
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-image: linear-gradient(
    to bottom,
    rgba(40, 44, 52, 0.9),
    rgb(55, 62, 75)
  );
  padding: 2px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 78px;
}

.header .dropdown-container {
  margin-left: auto; /* Push dropdown to the far right */
  display: flex;
  align-items: flex-end; /* Align dropdown to the bottom */
  height: 100%; /* Take full height of header */
}

#league-select {
  background-color: #f0f0f0;
  border: 2px solid #3498db;
  padding: 5px; /* Adjust padding */
  font-size: 12px; /* Adjust font size */
  border-radius: 4px;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 8px; /* Fine-tune bottom alignment */
}

.initial-league-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.header-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
}

.header-controls {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -3px; /* Space between buttons and dropdown */
  min-height: 80px; /* Ensure header retains its size */
}

.route-button {
  background-color: #26ab49;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
}

.logo {
  width: 79px;
  height: auto;
  margin-right: 1rem;
  margin-top: 0.1rem;
}
h1 {
  font-size: 1.2em;
  max-width: 90%;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-weight: bold;
  color: white;
}

h2 {
  font-size: 1.3em;
  transition: 0.3s ease-in-out;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-weight: bold;
}
button {
  background-color: #3dfc03;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.button-home,
.button-wordle,
.button-league {
  background-color: #007bff;
  color: white;
  padding: 7.9px 3.8px;
  border-radius: 5px;
  font-family: "Calibri";
}

.button-home {
  background-color: #26ab49;
}

.header-dropdown {
  margin-top: 3px; /* Add spacing between buttons and dropdown */
  /* margin-left: 10px; */
  height: 35px; /* Adjust height as needed */
  /* width: 150px; */
  /* overflow: hidden; Hide overflow if content exceeds height */
}

@media (max-width: 1100px) {
  .logo {
    width: 70px; /* Slightly smaller for medium screens */
  }
}

@media (max-width: 768px) {
  button {
    padding: 12px 24px;
    font-size: 16px;
    margin-top: 60px;
  }

  .contact-button,
  .button-home,
  .button-wordle,
  .button-league {
    padding: 3px 6px;
    font-size: 11.5px;
  }
  .logo {
    width: 60px; /* Even smaller for tablet screens */
  }
}

@media (max-width: 500px) {
  button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 60px;
    border-radius: 10px;
  }

  .contact-button,
  .button-home,
  .button-wordle,
  .button-league {
    padding: 2px 4px;
    font-size: 12px;
    border-radius: 4px;
  }

  .logo {
    width: 60px; /* Adjust for mobile screens */
  }
}
