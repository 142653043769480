/* Trying to fix adjustability to phones and tablets need to do in header
And fix dropdowns ! */
/* General container */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../../gtk-bki-cropped.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
/* Set a custom property for the full height */
.container {
  min-height: 100vh; /* Fallback for older browsers */
  min-height: -webkit-fill-available; /* Safari mobile */
}

.streak-container {
  position: fixed;
  top: 78px; /* Adjust based on your header */
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between the number and the icon */
  z-index: 1000; /* Ensure it layers on top */
}

.streak-number {
  /* font-size: 30px; */
  font-size: 2vw;
  color: black;
  font-weight: bold;
  font-family: "Calibri", sans-serif;
  margin-right: -15px; /* Ensure space between the number and the icon */
}

.streak-fire-icon {
  width: 3.5vw;
  height: 3.5vw;
}

/* Image styling */
.image-wordle {
  width: 250px;
  height: auto;
  margin-bottom: 10px;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%; /* Common width for categories and attempt history */
  margin: 0 auto; /* Center the container */
}

/* Category dropdown styling */
.category-wordle label {
  margin-bottom: 10px;
  color: white;
  display: block; /* Make label a block element to ensure it takes up full width */
  text-align: center; /* Center the text horizontally */
  font-size: 0.9rem;
}

.category-wordle select {
  background-color: #f0f0f0;
  border: 2px solid #3498db;
  padding: 12px;
  font-size: 0.7rem;
  /* font-size: 200px; */
  border-radius: 4px;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
  /* max-width: 100px;  */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
}

.category-wordle select:focus {
  border-color: #007bff;
}

.categories-wordle {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Space between dropdowns */
  width: 100%; /* Match the main container's width */
  margin-bottom: 10px; /* Add spacing below categories */
}

.category-wordle {
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Stacks label above the dropdown */
  margin-right: 10px;
  position: relative;
}

.dropdown-label2 {
  font-weight: bold;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.finish-text {
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.3em;
  white-space: pre-line;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 25px;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -10%;
  height: 100vh;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 25px;
  color: #333;
  text-align: center; /* Centers the text below the football */
}

.loading-screen p {
  margin: 0; /* Removes default margin */
  margin-left: 18px; /* Pushes the text 10px to the left */
  font-size: 25px;
}

.football {
  width: 80px;
  height: 80px;
  background: url("../../ball.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  animation: bounce 1s infinite ease-in-out;
  margin-bottom: 20px; /* Adds space between the ball and text */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

p {
  margin-top: 10px;
  font-size: 18px;
}

.error-message {
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 14px;
  color: white;
}

/* Button styling */
.guess-button2 {
  background-color: #26ab49;
  /* background-color: #3dfc03; */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-top: 25px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
}

/* Result styling */
.result {
  font-size: 34px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

/* Text styling */
.text {
  display: inline-block;
  padding: 8px;
  margin-top: 3px;
  font-size: 30px;
  font-weight: bold;
}

/* Message box styling */
.message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute; /* Ensure it is positioned within the container */
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Correct centering */
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Slightly transparent black background */
  color: white; /* White text for readability */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure it appears on top of other elements */
  line-height: 1.7;
  animation: fadeInPop 0.5s ease-out;
}

.message-box p {
  font-size: 20px;
  margin-bottom: 15px; /* Space between text and the button */
  white-space: pre-line; /* This allows \n to create line breaks */
  margin: 10px 0;
}

.message-box {
  padding: 20px 20px 20px; /* Adjust padding at the bottom to align closely with the button */
}

.message-box button {
  margin-top: 0; /* Ensure no additional spacing above the button */
}

.message-box button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.message-box button:hover {
  background-color: #0056b3;
}

/* Circle container styling */
.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 100px;
  box-sizing: border-box;
}

/* Circle and arrow styling */
.circle,
.arrow {
  width: 40px;
  height: 40px;

  /* width: 1.5vw;
  height: 1.5vw; */
  border-radius: 50%;
  margin-bottom: 7px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-out forwards;
}

.circle {
  border: none;
}

.arrow {
  /* font-size: 1.5vw; */
  font-size: 40px;
  color: white;
  background-color: transparent;
  line-height: 0;
}

.up-arrow::before {
  /* content: "▲"; */
  content: "↑";
  color: black;
}

.down-arrow::before {
  /* content: "▼"; */
  content: "↓";
  color: black;
}

/* Circle label styling */
.circle-label {
  /* font-size: 0.6vw; */
  font-size: 20px;
  margin-top: 8px;
  text-align: center;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  color: white;
  /* font-weight: 600; */
  white-space: nowrap;
}

/* Attempt History styling */
.attempt-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  margin-top: 10px;
  width: 120%;
  overflow-y: auto;
}

.attempt-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  opacity: 1;
  padding-bottom: 15px;
}

.attempt-history::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.attempt-history::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 8px; /* Rounded corners for the track */
}

.attempt-history::-webkit-scrollbar-thumb {
  background: #3498db; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.attempt-history::-webkit-scrollbar-thumb:hover {
  background: #2980b9; /* Darker color on hover */
}

/* Optional: You can add styling for the scrollbar corner */
.attempt-history::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/* Easing effect for the new attempt at the top */
@keyframes easeInEffect {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInPop {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.new-attempt {
  animation: easeInEffect 1s ease-out;
}

.correct {
  background-color: #10a30b;
}

.incorrect {
  background-color: #d61824;
}

@media (max-width: 1550px) {
  .container {
    padding-top: 20px;
    height: auto; /* Allow height to adjust */
  }

  .category-wordle select {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    padding: 8px; /* Reduce padding for better fit */
  }

  .category-wordle label {
    font-size: 0.85rem;
  }

  .streak-container {
    top: 78px;
    right: -3px;
    gap: 12px;
  }

  .streak-number {
    font-size: 25px;
  }

  .streak-fire-icon {
    width: 50px;
    height: 50px;
  }

  .image.wordle {
    width: 220px;
    height: 380px;
  }

  .circle,
  .arrow {
    width: 40px;
    height: 40px;
  }

  .arrow {
    font-size: 45px;
  }

  .circle-label {
    font-size: 17px;
  }

  .guess-button2 {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

/* Responsive adjustments */
@media (max-width: 1100px) {
  .finish-text {
    margin-top: 40px;
    font-size: 1.1em; /* Slightly smaller font size for medium screens */
  }
  .image-wordle {
    width: 240px;
  }
  .circle,
  .arrow {
    width: 33px;
    height: 33px;
  }

  .category-wordle label {
    font-size: 0.75rem;
  }

  .arrow {
    font-size: 38px;
  }
}
@media (max-width: 768px) {
  .finish-text {
    font-size: 1.1em; /* Reduce font size for tablets */
    margin-top: 120px;
  }

  .circle,
  .arrow {
    width: 30px;
    height: 30px;
  }

  .football {
    width: 70px;
    height: 70px;
  }

  .arrow {
    font-size: 35px;
  }
}

@media (max-width: 500px) {
  .container {
    padding-top: 20px;
    height: auto; /* Allow height to adjust */
  }

  .football {
    width: 50px;
    height: 50px;
  }

  .finish-text {
    margin-top: 30px;
    margin-bottom: 7px;
    font-size: 0.75em; /* Smaller font size for mobile devices */
    padding: 0 10px; /* Add padding for better spacing on smaller screens */
  }

  .circle-label {
    font-size: 2vw;
    font-weight: bold;
  }

  .attempt-history {
    max-height: 85px;
    margin-top: 5px;
    margin-bottom: 120px;
  }

  .category-wordle select {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
    padding: 8px; /* Reduce padding for better fit */
    width: auto;
    min-width: 60px;
    max-width: 100%; /* Ensure it doesn't exceed container width */
    width: 100%; /* Ensure the dropdown spans the container width */
  }
  .dropdown-label2 {
    font-size: 0.5rem;
  }
  .category-wordle label {
    font-size: 0.57rem;
  }
  .categories-wordle {
    flex-direction: column; /* Stack dropdowns vertically for smaller screens */
    gap: 10px; /* Add spacing between dropdowns */
  }

  .category-wordle {
    margin-right: 0; /* Remove horizontal margin to fit smaller screens */
  }

  .streak-container {
    top: 79px;
    right: -2px;
    gap: 10px;
  }

  .streak-number {
    font-size: 15px;
  }

  .streak-fire-icon {
    width: 27px;
    height: 27px;
  }

  .image-wordle {
    width: 180px;
    height: 180px;
  }

  .guess-button2 {
    padding: 10px 20px;
    font-size: 0.75rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .circle,
  .arrow {
    width: 20px;
    height: 20px;
  }

  .arrow {
    font-size: 25px;
  }

  @media (max-width: 390px) {
    .image-wordle {
      width: 120px;
      height: 120px;
    }

    .category-wordle select {
      font-size: 0.55rem; /* Adjust font size for smaller screens */
      padding: 2px; /* Reduce padding for better fit */
      width: auto;
      min-width: 40px;
      max-width: 100%; /* Ensure it doesn't exceed container width */
      width: 100%; /* Ensure the dropdown spans the container width */
    }

    .finish-text {
      margin-top: 11px;
      font-size: 0.75em; /* Smaller font size for mobile devices */
      padding: 0 10px; /* Add padding for better spacing on smaller screens */
    }

    .streak-container {
      top: 79px;
      right: 5px;
      gap: 10px;
    }

    .streak-number {
      font-size: 15px;
    }

    .streak-fire-icon {
      width: 25px;
      height: 25px;
    }
  }
}
