/* .header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-image: linear-gradient(
    to bottom,
    rgba(40, 44, 52, 0.9),
    rgb(55, 62, 75)
  );
  color: white;
  padding: 0 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow: hidden;
} */

.header .dropdown-container {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  height: 100%; /* Match the header height */
}

#league-select {
  margin-bottom: 8px; /* Align at the bottom visually */
}

.error-message {
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.4em;
}

.instruction-text {
  margin-bottom: 10px;
  font-size: 1.4em;
  text-align: center;
  color: #fcf7f7; /* Example color */
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
}

/* Optional: style the dropdown when focused */
#league-select:focus {
  outline: none;
  border-color: #2980b9; /* Darker blue when focused */
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5); /* Blue glow effect */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../gtk-bki-cropped.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Fallback for older browsers */
  min-height: -webkit-fill-available; /* Safari mobile */
}

.image {
  padding-top: 20px;
  width: 270px;
  height: auto;
  margin-bottom: 15px;
}

.image-wrapper {
  position: relative; /* For positioning the reset icon relative to the image */
  display: inline-block; /* Adjust layout for the image and reset icon */
}

.reset-icon {
  position: absolute;
  top: 45px; /* Adjust for vertical placement */
  right: 10px; /* Adjust for horizontal placement */
  width: 25px; /* Set the size of the icon */
  height: 25px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.reset-icon:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.game-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
}

.category label {
  margin-bottom: 10px;
  color: white;
  display: block; /* Make label a block element to ensure it takes up full width */
  text-align: center; /* Center the text horizontally */
}

.category select {
  background-color: #f0f0f0;
  border: 2px solid #3498db;
  padding: 12px;
  font-size: 0.8rem;
  /* font-size: 200px; */
  border-radius: 4px;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
  max-width: 340px; /* Limit the maximum width */
  /* max-width: 100px;  */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
}

.category select:focus {
  border-color: #007bff; /* Change border color on focus */
}

.categories {
  display: flex;
  justify-content: space-around; /* Spread categories evenly */
  align-items: center; /* Align items vertically */
  width: 100%; /* Ensure full width */
  margin-bottom: 10px;
  gap: 10px; /* Add spacing between categories */
}
.category {
  display: flex;
  flex-direction: column; /* Label above dropdown */
  align-items: center; /* Center align individual categories */
  flex: 1; /* All categories take equal width */
  max-width: 150px; /* Set a maximum size for categories */
}

.category:nth-child(2) {
  margin: 0 auto; /* Center the middle category horizontally */
  position: relative; /* Relative position for flexibility */
}

.dropdown-label {
  font-weight: bold;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.guess-button {
  background-color: #26ab49;
  /* background-color: #3dfc03; */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
}

.indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 140%;
  right: 140px;
  transform: translateY(-50%);
}

.text {
  display: inline-block;
  padding: 8px;
  margin-top: 3px;
  font-size: 30px;
  font-weight: bold;
}

/* Message box styling */
.message-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute; /* Ensure it is positioned within the container */
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Correct centering */
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Slightly transparent black background */
  color: white; /* White text for readability */
  padding: 45px 20px 5px; /* Extra padding at the top for the button */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure it appears on top of other elements */
  line-height: 1.7;
  animation: fadeInPop 0.5s ease-out;
}

.message-box2 p {
  font-size: 20px;
  margin-bottom: 20px; /* Space between text and the button */
  white-space: pre-line; /* This allows \n to create line breaks */
  margin: 5px 0;
}

.message-box2 button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.message-box2 {
  padding: 20px 20px 20px; /* Adjust padding at the bottom to align closely with the button */
}

.message-box2 button {
  margin-top: 0; /* Ensure no additional spacing above the button */
}

.message-box2 button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 0px; /* Distance from the top edge of the message box */
  right: 5px; /* Distance from the right edge of the message box */
  background: transparent;
  border: none;
  font-size: 20px; /* Larger size for visibility */
  font-weight: bold;
  color: white; /* Matches the text color */
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
  z-index: 15; /* Ensures it appears above other content in the box */
}

.close-button:hover {
  transform: scale(1.2); /* Slight zoom-in effect on hover */
  color: #ff4040; /* Change to a red shade for hover effect */
}

/* Container for the guess history */
.guess-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  margin-top: 20px;
  width: 120%;
  overflow-y: auto;
}

/* Each guess row */
.guess-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  opacity: 1;
  padding-bottom: 15px;
}

/* Icon container for circles/arrows */
.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 100px;
  box-sizing: border-box;
}

/* Status icon (formerly circle) */
.status-icon,
.direction-icon {
  width: 40px;
  height: 40px;

  /* width: 1.5vw;
  height: 1.5vw; */
  border-radius: 50%;
  margin-bottom: 7px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-out forwards;
}

.status-icon {
  border: none;
}

.direction-icon {
  font-size: 40px;
  color: white;
  background-color: transparent;
  line-height: 0;
}

/* Arrow indicators */
.up-icon::before {
  content: "↑"; /* Arrow up symbol */
  color: black;
}

.down-icon::before {
  content: "↓"; /* Arrow down symbol */
  color: black;
}

/* Label for each icon */
.icon-label {
  font-size: 20px;
  margin-top: 8px;
  text-align: center;
  font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  color: white;
  /* font-weight: 600; */
  white-space: nowrap;
}

.guess-history::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.guess-history::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 8px; /* Rounded corners for the track */
}

.guess-history::-webkit-scrollbar-thumb {
  background: #3498db; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.guess-history::-webkit-scrollbar-thumb:hover {
  background: #2980b9; /* Darker color on hover */
}

/* Optional: You can add styling for the scrollbar corner */
.guess-history::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/* Animation for new guess */
@keyframes easeInEffect {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-guess {
  animation: easeInEffect 1s ease-out;
}

.correct {
  background-color: #10a30b;
}

.incorrect {
  background-color: #d61824;
}

@keyframes fadeInPop {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@media (max-width: 1550px) {
  .container {
    padding-top: 20px;
    height: auto; /* Allow height to adjust */
  }

  .image {
    width: 210px;
    height: 360px;
  }
  .category select {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    padding: 8px; /* Reduce padding for better fit */
  }

  .status-icon,
  .direction-icon {
    width: 40px;
    height: 40px;
  }

  .direction-icon {
    font-size: 45px;
  }

  .icon-label {
    font-size: 20px;
  }

  .guess-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 1100px) {
  .image {
    width: 240px;
  }

  .status-icon,
  .direction-icon {
    width: 33px;
    height: 33px;
  }

  .direction-icon {
    font-size: 38px;
  }

  .category {
    width: 100%; /* Make each dropdown full-width */
    margin-right: 0; /* Remove horizontal margin */
  }
}

@media (max-width: 768px) {
  .image {
    width: 250px;
    height: 250px;
  }

  .football {
    width: 70px;
    height: 70px;
  }

  .status-icon,
  .direction-icon {
    width: 30px;
    height: 30px;
  }

  .direction-icon {
    font-size: 35px;
  }

  .reset-icon {
    top: 28px;
  }
}

@media (max-width: 500px) {
  .image {
    width: 200px; /* Smaller width for tablets */
    height: 200px;
    margin-bottom: 8px;
  }

  .football {
    width: 50px;
    height: 50px;
  }

  .icon-label {
    font-size: 2vw;
    color: white;
    font-weight: bold;
  }

  .status-icon,
  .direction-icon {
    width: 20px;
    height: 20px;
  }

  .direction-icon {
    font-size: 25px;
  }

  .guess-history {
    max-height: 120px;
    margin-top: 5px;
    margin-bottom: 120px;
  }

  .guess-button {
    padding: 10px 20px;
    font-size: 0.75rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .reset-icon {
    width: 20px; /* Set the size of the icon */
    height: 20px;
    top: 28px;
  }

  .close-button {
    right: 1px; /* Distance from the right edge of the message box */
  }

  .category {
    width: 100%; /* Make each dropdown full-width */
    margin-right: 0; /* Remove horizontal margin */
  }

  .dropdown-label {
    font-size: 0.5rem;
  }

  .category label {
    font-size: 0.57rem;
  }

  .category select {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    padding: 8px; /* Reduce padding for better fit */
  }
}

@media (max-width: 390px) {
  .container {
    padding-top: 20px;
    height: auto; /* Allow height to adjust */
  }
  .categories {
    margin-top: 2px;
  }

  .reset-icon {
    width: 18px; /* Set the size of the icon */
    height: 18px;
    top: 17px;
  }

  .guess-history {
    margin-top: 5px;
    max-height: 100px;
  }
  .error-message {
    font-size: 14px;
    color: white;
    font-family: "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  }

  .image {
    width: 150px; /* Even smaller for mobile devices */
    height: 150px;
    margin-bottom: 10px;
  }

  .instruction-text {
    font-size: 1rem;
  }

  #league-select {
    font-size: 8px;
    padding: 4px;
  }
  .guess-button {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .category select {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
    padding: 2px; /* Reduce padding for better fit */
    min-width: 40px;
    max-width: 100%; /* Ensure it doesn't exceed container width */
    width: 100%; /* Ensure the dropdown spans the container width */
  }
  .dropdown-label {
    font-size: 0.5rem;
  }
  .category label {
    font-size: 0.5rem;
  }
  .categories {
    flex-direction: column; /* Stack categories vertically */
  }

  .category {
    flex: 0 1 100%; /* Take full width for each category */
    max-width: 100%; /* Ensure no size mismatch */
  }
}
